const DOMMap = {
    filters: null,
    blockTitles: null,
    contentItems: null,
    clearFilters: null,
    searchInput: null,
    searchButton: null,
};
const state = {
    activeFilters: null
};
const setState = () => {
    state.activeFilters = [];
};
const searchInputSelector = '[data-role="search-filter"]';
const initDOM = () => {
    DOMMap.filters = $('[data-filter]');
    DOMMap.blockTitles = $('[data-role="content-block"]');
    DOMMap.contentItems = $('[data-role="content-item"]');
    DOMMap.clearFilters = $('[data-role="filter-clear"]');
    DOMMap.searchInput = $(searchInputSelector);
    DOMMap.searchButton = $('[data-role="button-search"]');
};
const initHandler = () => {
    DOMMap.filters.find('input').on('change', handleFiltersChange);
    DOMMap.clearFilters.on('click', handleClearFiltersClick);
    DOMMap.searchButton.on('click', handleSearchButtonClick);

};
const handleSearchButtonClick = function(e){
    e.preventDefault();
    let filteredDOM = DOMMap.contentItems;
    let input = $(this).parent().parent().find(searchInputSelector);
    filteredDOM = filterContent(filteredDOM, [input.val()]);
    if (filteredDOM.length !== DOMMap.contentItems.length) {
        showFiltredContent(filteredDOM);
    }
    else {
        clearFilter();
    }
};
const handleFiltersChange = function () {
    let filter = $(this).parent();
    if (isActive(filter.data('filter'))) {
        removeActiveFilter(filter.data('filter'));
    }
    else {
        addActiveFilter(filter.data('role').replace('filter-', ''), filter.data('filter'));
    }
    changeContent();
};
const handleClearFiltersClick = function (e) {
    e.preventDefault();
    DOMMap.filters.find('input').each(function () {
        $(this)[0].checked= false;
    });
    clearFilter();
};
const isActive = (key) => {
    let titles = [];
    Object.values(state.activeFilters).forEach((filter) => {
        filter.forEach((item) => {
            titles.push(item);
        });
    });
    return titles.indexOf(key) + 1;

};

const addActiveFilter = (type, key) => {
    if (Object.keys(state.activeFilters).length === 0) {
        state.activeFilters[type] = [];
    }
    else {
        if (!arrHasKey(state.activeFilters, type)) {
            state.activeFilters[type] = [];
        }
    }
    state.activeFilters[type].push(key);
};
const arrHasKey = (arr, key) => {
    for (let item of Object.keys(arr)) {
        if (item === key) {
            return true;
        }
    }
    return false;
};
const removeActiveFilter = (key) => {
    let newState = [];
    for (let index in state.activeFilters) {
        let filtered = state.activeFilters[index].filter(
            function (el) {
                return el !== key;
            }
        );
        if (filtered.length > 0) {
            newState[index] = filtered;
        }
    }
    state.activeFilters = newState;
};
const changeContent = () => {
    let filteredDOM = DOMMap.contentItems;
    for (let index in state.activeFilters) {
        if (index === "block") {
            filteredDOM = filterBlock(filteredDOM, state.activeFilters[index]);
        }
        else {
            filteredDOM = filterContent(filteredDOM, state.activeFilters[index]);
        }
    }
    if (filteredDOM.length !== DOMMap.contentItems.length) {
        showFiltredContent(filteredDOM);
    }
    else {
        clearFilter();
    }

};
const filterBlock = (domList, params) => {
    let newDomList = [];
    for (let index = 0; index < domList.length; index++) {
        let blockTitle = $(domList[index]).parent().parent();
        for (let i = 0; i < params.length; i++) {
            if (blockTitle.find('.card-title').text() === params[i]) {
                newDomList.push($(domList[index]));
            }
        }
    }
    return newDomList;
};
const filterContent = (domList, params) => {
    let newDomList = [];
    for (let index = 0; index < domList.length; index++) {
        let contentItem = $(domList[index]).find('a');
        for (let i = 0; i < params.length; i++) {
            if (contentItem.text().indexOf(params[i]) + 1) {
                newDomList.push($(domList[index]));
            }
        }
    }
    return newDomList;
};
const showFiltredContent = (elements) => {
    DOMMap.contentItems.hide();
    for (let i = 0; i < elements.length; i++) {
        elements[i].show();
    }
};
const clearFilter = () => {
    DOMMap.contentItems.show();
};
class Filter {
    init() {
        initDOM();
        setState();
        initHandler();

    }
}
export default Filter = new Filter();